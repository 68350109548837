import React, { Component } from 'react'
import Fecha from '../assets/img/fecha1.jpg'
import Fecha2 from '../assets/img/fecha2.jpg'
import Fecha3 from '../assets/img/fecha3.jpg'
import Fecha4 from '../assets/img/fecha4.jpg'
import Fecha5 from '../assets/img/10.jpg'

export default class fechas extends Component {
  render() {
    return (
      <>
     
        <section class="section container-is-fullhd	">
        <h2 class="title">Eventos Pasados</h2>
           <div className='columns is-centered is-mobile is-multiline '>
                <div className='column has-text-centered is-narrow '>
                        <div class="card fecha">
                            <div class="card-image">
                                <figure class="image is-2by3">
                                <img
                                    src={Fecha}
                                    alt="Placeholder image"
                                />
                                </figure>
                            </div>
                        </div>
                </div>
                <div className='column has-text-centered is-narrow '>
                        <div class="card fecha">
                            <div class="card-image">
                                <figure class="image is-2by3">
                                <img
                                    src={Fecha2}
                                    alt="Placeholder image"
                                />
                                </figure>
                            </div>
                        </div>
                </div>

                <div className='column has-text-centered is-narrow '>
                        <div class="card fecha">
                            <div class="card-image">
                                <figure class="image is-2by3">
                                <img
                                    src={Fecha3}
                                    alt="Placeholder image"
                                />
                                </figure>
                            </div>
                        </div>
                </div>
                
                <div className='column has-text-centered is-narrow  '>
                        <div class="card fecha">
                            <div class="card-image">
                                <figure class="image is-2by3">
                                <img
                                    src={Fecha5}
                                    alt="Placeholder image"
                                />
                                </figure>
                            </div>
                        </div>
                </div>
                </div>
            </section>     
      </>
    )
  }
}
