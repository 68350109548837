import React, { Component } from 'react'
import cardImage from '../assets/img/crew.jpg'
import thumb from '../assets/img/TXC.png'

export default class bio extends Component {
  render() {
    return (
        <>
        <section class="section carding mt-6">
            <div class="container has-text-justify">
            <div class="columns is-centered is-desktop" >
               
                <div class="column">
                    <div className='card mt-6'>
                        <div class="card-content ">
                        <div class="card-image has-text-centered">
                            <figure class="image is-130x130 is-inline-block">
                            <img
                                src={thumb}
                                alt="Placeholder image"
                                className='thumb'
                            />
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="content has-text-left is-size-6 descrip">
                               Somos un colectivo integrado por múltiples comediantes que van iniciando y otros  con más años de experiencia, hacemos desde eventos, creamos contenido, conducimos, hosteamos y amenizamos fiestas, reuniones,
                               fomentamos la comedia a traves de <b>open mic's</b> (GRATIS) y cobramos por presentaciones colectivas o privadas, así como para todo tipo de aforos y audiencias nosostros nos acoplamos.
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
                <div class="column">
                <div className=' card mt-6'>
                    <div class="card-content">
                            <div class="media">
                            <iframe width="100%" height="650px" src="https://www.youtube.com/embed/fpOGS7mO1rU?si=xVJcczYyf1WY1txY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="column">
                <div class="card mt-6">
                        <div class="card-image">
                            <figure class="image is-4by5" >
                                <img src={cardImage}   alt="Joseddo Guitarra"/>
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="media">
                                <div class="media-content">
                                    <p class="title is-4">TOXICOMEDIA</p>
                                    <p class="subtitle is-6 mt-3 descrip"> | Show del Flaco Mendez | Comala, Colima <br></br>| Junio 2024</p>
                                </div>
                            </div>

                            <div class="content">
                                <a href='https://www.facebook.com/profile.php?id=61551786251086'>Siguenos en Facebook </a>.
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </section>
        </>
    )
  }
}
