import React, {   useRef, useState } from 'react'
import coverPicture from '../assets/img/00.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../src/styles.css'

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Cover(){
   

    return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> 
          <img src={coverPicture}  alt='tag' className='coverPicture'/> 
        </SwiperSlide>
        
        
       
      </Swiper>
      
    </>
    
    )
  }

