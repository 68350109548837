
import './App.css';
import Navbar from './components/nav'
import CoverPage from './components/cover';
import BioPage from './components/bio'
import Fecha from './components/fechas'
import Footer from './components/footer'


function App() {
  
  return (
      <>
      <CoverPage class='slider'/>
       
      <BioPage />
      <Fecha />
        <section class="section">
            <div class="container has-text-centered">
                <h2 class="title">Información y Contacto</h2>
                <a href='https://api.whatsapp.com/send?phone=+523121043195&text=Hola%2C%20Me%20interesar%C3%ADa%20saber%20como%20contratar%20los%20servicios' className='button is-success is-outlined is-link'><i className=" fab mr-2 fa-whatsapp fa-2x "> </i> Contactar </a>
            </div>
        </section>
      <Footer />
        
      </>
  );
}

export default App;
