import React, { Component } from 'react'

export default class footer extends Component {
  render() {
    return (
      <>
        <footer class="footer">
            <div class="container">
                <div class="content has-text-centered is-vcentered">
                    <p>
                        
                        <a href="https://www.youtube.com/channel/toxicomedia">
                            <i class="fab fa-youtube-square fa-2x"></i>
                        </a>
                        <a href="https://www.tiktok.com/@toxicomediacolima?is_from_webapp=1&sender_device=pc#">
                            <i class="fa-brands fa-tiktok fa-2x"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61551786251086">
                            <i class="fab fa-facebook fa-2x"></i>
                        </a>
                    </p>
                    <p>
                      
                    </p>
                </div>
            </div>
        </footer>

      
      </>
    )
  }
}
